<script context="module">
    import mediaList from './json/media-list.json';
</script>

<script>
    import { createEventDispatcher } from 'svelte';

    export let displayCards = true;

    let dispatch = createEventDispatcher();
</script>

<style>
    .box, .card {
        cursor: pointer;
    }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9NZWRpYUxpc3Quc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7SUFDSTtRQUNJLGVBQWU7SUFDbkIiLCJmaWxlIjoic3JjL01lZGlhTGlzdC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgICAuYm94LCAuY2FyZCB7XG4gICAgICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgICB9XG4iXX0= */</style>

<div class="columns is-multiline">
    {#each mediaList as media}
        {#if displayCards}
            <div class="column is-half-tablet is-one-third-desktop is-one-quarter-widescreen is-one-quarter-fullhd">
                <div class="card" on:click="{() => dispatch('select', media)}">
                    <div class="card-image">
                        <figure class="image is-16by9">
                            <img alt="[thumbnail]" src="{media.thumbnail}">
                        </figure>
                    </div>
                    <div class="card-content">
                        <p class="title is-6">{media.title}</p>
                        <div class="tags">
                            {#each media.captionLanguages as lng}
                                <span class="tag">{lng}</span>
                            {/each}
                        </div>
                    </div>
                </div>
            </div>
        {:else}
            <div class="column is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd">
                <div class="box" on:click="{() => dispatch('select', media)}">
                    <article class="media">
                        <figure class="media-left">
                            <p class="image">
                                <img alt="[thumbnail]" src="{media.thumbnail}">
                            </p>
                        </figure>
                        <div class="media-content">
                            <p class="title is-6">{media.title}</p>
                            <div class="tags">
                                {#each media.captionLanguages as lng}
                                    <span class="tag">{lng}</span>
                                {/each}
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        {/if}
    {/each}
</div>