<script>
    import { createEventDispatcher } from 'svelte';
    import { slide } from 'svelte/transition';
    import MediaProgress from './MediaProgress.svelte';
    import './svg/play.svg';
    import './svg/pause.svg';
    import './svg/step-backward.svg';
    import './svg/step-forward.svg';
    import './svg/combine-left-right.svg';
    import './svg/sound-medium.svg';
    import './svg/sound-off.svg';
    import './svg/line-angle-up.svg';
    import './svg/line-angle-down.svg';
    import './svg/search.svg';

    export let currentTime = 0,
        duration = 0,
        playbackRate = 1,
        offset = 0,
        volume = 100,
        muted = false,
        playing = false,
        repeating = false;

    let element, showSettings = false;

    const dispatch = createEventDispatcher();

    function handleVolumeClick() {
        volume = 1;
        muted = !muted;
        dispatch('volumechange', {
            "volume": volume,
            "muted": muted
        });
    }

    function handlePlaybackRateInput(event) {
        playbackRate = parseFloat(event.target.value);
    }

    function handlePlaybackRateChange(event) {
        dispatch('ratechange', {
            "playbackRate": playbackRate
        });
    }

    function handleOffsetChange(event) {
        offset = parseFloat(event.target.value);
        dispatch('offsetchange', {
            "offset": offset
        });
    }
</script>

<style type="text/scss">.media-controls__button {
  cursor: pointer;
  display: inline-block;
  margin: 0.8rem;
  width: 2rem;
  height: 2rem; }
  .media-controls__button svg {
    fill: white;
    width: 2rem;
    height: 2rem; }
  .media-controls__button:hover svg {
    filter: drop-shadow(0px 0px 12px white); }
  .media-controls__button.is-active svg {
    fill: cyan; }
  .media-controls__progress {
  position: relative;
  display: block;
  height: 1em; }
  .media-controls__progress--empty {
  display: none; }
  .media-controls__settings {
  border-top: 1px solid gray;
  padding-top: 0.5em;
  padding-bottom: 0.5em; }
  input[type="range"].slider {
  margin: 0; }
  input[type="range"].slider + output {
    top: 0 !important; }
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9NZWRpYUNvbnRyb2xzLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQWU7RUFDZixxQkFBcUI7RUFDckIsY0FBYztFQUNkLFdBQVc7RUFDWCxZQUFZLEVBQUU7RUFDZDtJQUNFLFdBQVc7SUFDWCxXQUFXO0lBQ1gsWUFBWSxFQUFFO0VBQ2hCO0lBQ0UsdUNBQXVDLEVBQUU7RUFDM0M7SUFDRSxVQUFVLEVBQUU7RUFFaEI7RUFDRSxrQkFBa0I7RUFDbEIsY0FBYztFQUNkLFdBQVcsRUFBRTtFQUVmO0VBQ0UsYUFBYSxFQUFFO0VBRWpCO0VBQ0UsMEJBQTBCO0VBQzFCLGtCQUFrQjtFQUNsQixxQkFBcUIsRUFBRTtFQUV6QjtFQUNFLFNBQVMsRUFBRTtFQUNYO0lBQ0UsaUJBQWlCLEVBQUUiLCJmaWxlIjoic3JjL01lZGlhQ29udHJvbHMuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiLm1lZGlhLWNvbnRyb2xzX19idXR0b24ge1xuICBjdXJzb3I6IHBvaW50ZXI7XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgbWFyZ2luOiAwLjhyZW07XG4gIHdpZHRoOiAycmVtO1xuICBoZWlnaHQ6IDJyZW07IH1cbiAgLm1lZGlhLWNvbnRyb2xzX19idXR0b24gc3ZnIHtcbiAgICBmaWxsOiB3aGl0ZTtcbiAgICB3aWR0aDogMnJlbTtcbiAgICBoZWlnaHQ6IDJyZW07IH1cbiAgLm1lZGlhLWNvbnRyb2xzX19idXR0b246aG92ZXIgc3ZnIHtcbiAgICBmaWx0ZXI6IGRyb3Atc2hhZG93KDBweCAwcHggMTJweCB3aGl0ZSk7IH1cbiAgLm1lZGlhLWNvbnRyb2xzX19idXR0b24uaXMtYWN0aXZlIHN2ZyB7XG4gICAgZmlsbDogY3lhbjsgfVxuXG4ubWVkaWEtY29udHJvbHNfX3Byb2dyZXNzIHtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICBkaXNwbGF5OiBibG9jaztcbiAgaGVpZ2h0OiAxZW07IH1cblxuLm1lZGlhLWNvbnRyb2xzX19wcm9ncmVzcy0tZW1wdHkge1xuICBkaXNwbGF5OiBub25lOyB9XG5cbi5tZWRpYS1jb250cm9sc19fc2V0dGluZ3Mge1xuICBib3JkZXItdG9wOiAxcHggc29saWQgZ3JheTtcbiAgcGFkZGluZy10b3A6IDAuNWVtO1xuICBwYWRkaW5nLWJvdHRvbTogMC41ZW07IH1cblxuaW5wdXRbdHlwZT1cInJhbmdlXCJdLnNsaWRlciB7XG4gIG1hcmdpbjogMDsgfVxuICBpbnB1dFt0eXBlPVwicmFuZ2VcIl0uc2xpZGVyICsgb3V0cHV0IHtcbiAgICB0b3A6IDAgIWltcG9ydGFudDsgfVxuXG4vKiMgc291cmNlTWFwcGluZ1VSTD1NZWRpYUNvbnRyb2xzLnN2ZWx0ZS5jc3MubWFwICovIl19 */</style>

<div class="media-controls__progress" class:media-controls__progress--empty="{duration == 0}">
    <MediaProgress
        currentTime="{currentTime}"
        duration="{duration}"
        on:timeupdate />
</div>

<div class="media-controls__buttons container">
    <nav class="level is-mobile">
        <div class="level-left">
            <div class="level-item">
                <span class="media-controls__button" on:click="{() => dispatch('search')}">
                    <svg class="icon">
                        <use xlink:href="/sprites.svg#search"/>
                    <svg>
                </span>
            </div>
        </div>
        <div class="level-item">
            <span class="media-controls__button" class:is-active="{repeating}" on:click="{() => dispatch('repeat')}">
                <svg class="icon">
                    <use xlink:href="/sprites.svg#combine-left-right"/>
                <svg>
            </span>
            <span class="media-controls__button" on:click="{() => dispatch('previous')}">
                <svg class="icon">
                    <use xlink:href="/sprites.svg#step-backward"/>
                <svg>
            </span>
            <span class="media-controls__button" on:click="{() => playing ? dispatch('pause') : dispatch('play')}">
                <svg class="icon">
                    <use xlink:href="/sprites.svg#{playing ? 'pause' : 'play'}"/>
                <svg>
            </span>
            <span class="media-controls__button" on:click="{() => dispatch('next')}">
                <svg class="icon">
                    <use xlink:href="/sprites.svg#step-forward"/>
                <svg>
            </span>
            <span class="media-controls__button" on:click="{handleVolumeClick}">
                <svg class="icon">
                    <use xlink:href="/sprites.svg#{(volume > 0 && !muted) ? 'sound-medium' : 'sound-off'}"/>
                <svg>
            </span>
        </div>
        <div class="left-right">
            <div class="level-item">
                <span class="media-controls__button" on:click="{() => {showSettings = !showSettings;}}">
                    <svg class="icon">
                        <use xlink:href="/sprites.svg#{showSettings ? 'line-angle-down' : 'line-angle-up'}"/>
                    <svg>
                </span>
            </div>
        </div>
    </nav>
</div>

{#if showSettings}
    <div class="media-controls__settings" transition:slide>
        <div class="container">
            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="label">Speed</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control media-controls__playback-rate">
                            <input class="slider is-fullwidth has-output" type="range" min="0.25" max="2" step="0.25" value="{playbackRate}" on:input="{handlePlaybackRateInput}"  on:change="{handlePlaybackRateChange}">
                            <output>{playbackRate}</output>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field is-horizontal">
                <div class="field-label">
                    <label class="label">Offset</label>
                </div>
                <div class="field-body">
                    <div class="field">
                        <div class="control">
                            <input class="input" type="number" step="0.1" value="{offset}" on:change="{handleOffsetChange}">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
{/if}