<script context="module">
    import Notification from './Notification.svelte';

    let container;

    export function notify(options) {

        if (typeof options === 'string' || options instanceof String) {
            options = {
                "message": options
            };
        }

        const notif = new Notification({
            "target": container,
            "props": options,
            "intro": true
        });

        notif.$on('close', () => {
            notif.$destroy();
        });

        return notif;
    }

    document.addEventListener('DOMContentLoaded', () => {
        container = document.createElement('div');
        container.classList.add('notify');
        document.body.appendChild(container);
    });
</script>

<style type="text/scss">:global(.notify) {
  position: fixed;
  display: flex;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2em;
  overflow: hidden;
  pointer-events: none;
  /* column, column-reverse */
  flex-direction: column-reverse; }
  :global(.notify) :global(.notification) {
    display: inline-flex;
    pointer-events: auto;
    max-width: 600px;
    margin: 0.5em 0;
    opacity: 0.9;
    /* flex-start, center, flex-end */
    align-self: flex-end; }
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9Ob3RpZnkuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZUFBZTtFQUNmLGFBQWE7RUFDYixhQUFhO0VBQ2IsTUFBTTtFQUNOLFFBQVE7RUFDUixTQUFTO0VBQ1QsT0FBTztFQUNQLFlBQVk7RUFDWixnQkFBZ0I7RUFDaEIsb0JBQW9CO0VBQ3BCLDJCQUEyQjtFQUMzQiw4QkFBOEIsRUFBRTtFQUNoQztJQUNFLG9CQUFvQjtJQUNwQixvQkFBb0I7SUFDcEIsZ0JBQWdCO0lBQ2hCLGVBQWU7SUFDZixZQUFZO0lBQ1osaUNBQWlDO0lBQ2pDLG9CQUFvQixFQUFFIiwiZmlsZSI6InNyYy9Ob3RpZnkuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiOmdsb2JhbCgubm90aWZ5KSB7XG4gIHBvc2l0aW9uOiBmaXhlZDtcbiAgZGlzcGxheTogZmxleDtcbiAgei1pbmRleDogMTAwMDtcbiAgdG9wOiAwO1xuICByaWdodDogMDtcbiAgYm90dG9tOiAwO1xuICBsZWZ0OiAwO1xuICBwYWRkaW5nOiAyZW07XG4gIG92ZXJmbG93OiBoaWRkZW47XG4gIHBvaW50ZXItZXZlbnRzOiBub25lO1xuICAvKiBjb2x1bW4sIGNvbHVtbi1yZXZlcnNlICovXG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW4tcmV2ZXJzZTsgfVxuICA6Z2xvYmFsKC5ub3RpZnkpIDpnbG9iYWwoLm5vdGlmaWNhdGlvbikge1xuICAgIGRpc3BsYXk6IGlubGluZS1mbGV4O1xuICAgIHBvaW50ZXItZXZlbnRzOiBhdXRvO1xuICAgIG1heC13aWR0aDogNjAwcHg7XG4gICAgbWFyZ2luOiAwLjVlbSAwO1xuICAgIG9wYWNpdHk6IDAuOTtcbiAgICAvKiBmbGV4LXN0YXJ0LCBjZW50ZXIsIGZsZXgtZW5kICovXG4gICAgYWxpZ24tc2VsZjogZmxleC1lbmQ7IH1cblxuLyojIHNvdXJjZU1hcHBpbmdVUkw9Tm90aWZ5LnN2ZWx0ZS5jc3MubWFwICovIl19 */</style>